import { render, staticRenderFns } from "./BlastInfoPanel.vue?vue&type=template&id=2a0baf63&scoped=true&"
import script from "./BlastInfoPanel.vue?vue&type=script&lang=js&"
export * from "./BlastInfoPanel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a0baf63",
  null
  
)

export default component.exports